.infoFile {
  padding-right: 10px;
  font-size: 12px;
  width: 200px;
}

a.infoFileName {
  padding-right: 10px;
  font-size: 14px;
  width: 180px !important;
  word-wrap: normal;
  width: auto;
  white-space: nowrap;
}

.divInfoFileName {
  width: 200px;
  inline-size: 200px;
  overflow: hidden;
  position: relative;
}

.blender {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 160;
  right: 0;
  width: 40px;
  height: 20px;
  background-size: 100% 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 100%);
  pointer-events: none;
}
