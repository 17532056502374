.content {
  padding: 0 20px;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  clear: both;
  position: relative;
  display: block;
}

.pagePanelGolbal {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #c1c7d0;
  border-radius: 5px;
  display: inline-block;
  margin: 50px auto 0;
  margin-top: 150px;
  padding: 20px;
  text-align: left;
  width: 580px;
  height: auto;
  background: #fff;
}

.page-panel-inner {
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}

.page-panel-content {
  box-sizing: border-box;
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}

.headerStyel {
  color: darkblue;
}

.headerStyel h1 {
  color: #244fc9;
}

.group {
  margin-top: 10px;
  padding-top: 0;
  box-sizing: border-box;
  clear: both;
  padding: 4px 0 4px 145px;
  position: relative;
  margin: 1px 0;
  width: 100%;
}

fieldset {
  border: 0;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

.group:before {
  content: " ";
  display: table;
}

legend {
  display: block;
  float: left;
  margin-left: -145px;
  padding: 5px 0 0;
  position: relative;
  text-align: right;
  width: 130px;
  word-wrap: break-word;
  color: #6b778c;
  font-weight: 500;
  font-size: 14px;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.matrix {
  padding-top: 0px;
  padding-left: 142px;
  display: grid;
}
.radio {
  padding: 0 0 0 20px;
  margin: 5px 0 0;
  position: relative;
}

.radio:first-child {
  margin-top: 0;
}

.fieldGroup {
  box-sizing: border-box;
  clear: both;
  padding: 4px 0 4px 145px;
  position: relative;
  margin: 1px 0;
  width: 100%;
}

.fieldGroup::before {
  content: " ";
  display: table;
}

.buttonsContainer {
  margin-top: 1px;
  box-sizing: border-box;
  clear: both;
  margin: 1px 0 0;
  padding: 4px 0 4px 145px;
  position: relative;
  width: 100%;
}
.buttons {
  font-size: 0;
}

.FormHeader {
  color: #2965cb;
  font-size: 24px;
  font-weight: 500;
  align-content: "left";
  padding-left: 0px;
}

.headerbannerResetPassword .headerBody {
  top: 0px;
  text-align: left;
}

.linkclass {
  color: #2965cb;
  text-decoration: none;
}


.css-cni8ru-SectionMessage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  padding: 0px !important;
  border-radius: 3px;
  align-items: center !important;
  height: 40px !important;
}

.WpXpw {
  background: #FFFFFF;
  border-radius: 3px;
  box-sizing: content-box;
  color: #091E42;
  max-height: 448px;
  max-width: 448px;
  padding: 8px 8px !important;
  z-index: 200;
  box-shadow: 0 4px 8px -2pxrgba(9,30,66,0.25),0 0 1pxrgba(9,30,66,0.31);
}

