.headerBody {
  font-size: 14px;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 35px;
  position: fixed;
  left: 0px;
  z-index: 80;
  background-color: rgb(244, 245, 247);
  width: 100%;
}

.headerSection {
  padding-left: 10px;
  color: rgb(23, 43, 77);
  font-size: 14px;
  margin-top: -5px;
}

.redPara{
  color: red;

}
