.panelHeader {
  font-size: 20px;
  color: #0052cc;
  text-align: left;
  font-weight: 600;
}

.individualState {
  height: 60px;
  width: auto;
  margin-top: 40px;
  margin-left: 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.nameState {
  width: 40px;
  height: 40px;
  font-size: 10px;

  text-align: center;
}

.circleSection {
  background-color: #808080;
  border-radius: 50%;
  z-index: 99;
  width: 35px;
  height: 35px;
  position: relative;
}

.rectangleSection {
  width: 60px;
  height: 7px;
  background-color: #808080;
  float: left;
  vertical-align: middle;
  position: relative;
}

.rectangleSection:first-child {
  visibility: hidden;
  background-color: #ffffff;
}

.drawState {
  height: 100px;
  align-items: center;
  display: -ms-flexbox;
  display: -webkit-flex;
}
s
.ContainerState {
  align-items: center;
  display: flex;
  height: 120px;
}
.buttonWrapperClass{
  opacity: 0;
}
.contentClass{
  opacity: 0;
}

.css-n7tliy-MuiStep-root {
  padding-left: 31px !important;
  padding-right: -41px !important;
}


.css-10e595q-MuiStepConnector-root {
  left: calc(-50% + 5px) !important;
  right: calc(50% + 5px) !important;
}

.css-ac60a1 {
  left: calc(-50% + -10px)!important;
  right: calc(50% + 7px)!important;
}

.css-166ciyp {
  padding-left: 8px !important;
  padding-right: 32px !important;
}