.tablecontent {
    background-color: white;
    width: 92.5vw;
    margin-left: 20px;
    height: auto;
    max-height: 250px;
    padding-bottom: 20px;
  }
  
  .linkclass {
    color: #2965cb;
    text-decoration: none;
  }
  
  .dashboard-dsp{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin: 0;
    min-height: 24px;
    padding: 0;
    position: relative;
  }
  
  #dashboard {
    background: #fff;
    border-color: #ddd;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    box-sizing: border-box;
    display: block;
    padding: 0;
    position: relative;
    margin: 0;
    width: 100%;
  }
  .dashboard-content {
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    margin: 0;
    position: relative;
    background: #f4f5f7;
  }
  .aui-page-header {
    padding: 20px;
    background-color: #f4f5f7;
  }
  
  .aui-page-header-inner {
    border-spacing: 0;
    box-sizing: border-box;
    display: table;
    table-layout: auto;
    width: 100%;
  }
  
  .aui-page-header-main {
    box-sizing: border-box;
    display: table-cell;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: middle;
  }
  
  .layout {
    clear: both;
    padding: 0 20px 20px 20px;
    position: relative;
  }
  
  .layout-a .column {
    width: 98%;
    display: block;
    float: left;
    list-style-type: none;
    margin: 0 0 -1.5em 2%;
    min-height: 200px;
    padding: 0;
    position: relative;
    width: 47.5%;
  }
  
  ul:first-child {
    margin-top: 0;
  }
  
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  h1 {
    color: #244fc9;
  }
  
  h3 {
    font-style: inherit;
    line-height: 1.2;
    color: #fdfeff;
    font-weight: 500;
    letter-spacing: -0.008em;
    margin-top: 27px;
    padding-top: 5px;
    padding-left: 19px;
    font-size: 14px;
  }
  
  .dashboard-item-title {
    background: transparent;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.666666666667;
    margin: 0;
    padding: 4px 64px 4px 10px;
    cursor: move;
    color: #fff !important;
  }
  
  .layout {
    clear: both;
    padding: 0 20px 20px 50px;
    position: relative;
  }
  
  .css-5x5hrx {
    margin-left: 16px;
  }
  
  .css-150v7yg-EmptyViewContainer {
    margin: auto;
    padding: 10px;
    text-align: center;
    width: 98% !important;
  }
  
  .css-v3yuj6 {
    border-bottom: 2px solid var(--ds-border-neutral, var(--ds-border-neutral, #dfe1e6));
  }
  
  .css-6fzh4j {
    border-bottom: 2px solid var(--ds-border-neutral, var(--ds-border-neutral, #dfe1e6));
  }
  
  .css-1ljo265 {
    margin: auto;
    padding: 10px;
    text-align: center;
  
    width: 98% !important;
  }
  
  .homeHeader {
    color: #2965cb;
    padding-left: 30px;
    font-size: 24px;
    font-weight: 500;
    align-content: "left";
  }
  
  .dashboardContent {
    padding-top: 85px;
  }
  
  .no-body {
    text-align: left;
  }
  