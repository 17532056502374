body {
  background-color: #f4f5f7 !important;
}

.title {
  font-size: 30;
}

.from-style {
  background-color: #f4f5f7;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.footer-style {
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
}

.footer {
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  width: 50%;
}

.titleheader {
  color: crimson;
}

.login-panel {
  background: #fff;
  border: 1px solid #c1c7d0;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
  height: auto;
  width: 560px;
}
.footer {
  clear: both;
  float: left;
  width: 100%;
  display: block;
}

.footer-body {
  color: #7a869a;
  font-size: 12px;
  line-height: 1.66666667;
  margin: 20 px 0;
  padding: 0 10 px 21 px;
  min-height: 44 px;
  text-align: center;
}
.atlassian-footer {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin: 10 px 0 0;
}

.ul {
  display: block;
  font-size: 0;
  list-style: none;
  padding: 0;
}

.li {
  display: inline-block;
  font-size: 12px;
  line-height: 1.66666667;
  padding: 0;
  white-space: nowrap;
}
li::after {
  content: "\B7";
  margin-left: 10 px;
  speak: none;
}

.a {
  color: #285bb3;
  text-decoration: underline;
}

.spaceFooter {
  margin: 10 px 0 0;
}

#footer-logo {
  background: #f5f5f5;
  position: relative;
  bottom: -21 px;
}

.footer-logo-a {
  background-position-x: center;
  background-position-y: bottom;
  background-size: 127 px 26 px;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: 127 px 26 px;
}

.aui-message-err {
  max-width: fit-content;
  margin: "auto";
  word-wrap: break-word;
  background-color: black !important;
}

.css-1lo6yt6 {
  background-color: #181715;
  color: #2965cb;
  max-height: 55 px;
  overflow: visible;
}
p {
  word-break: normal;
  white-space: pre-wrap;
}

.css-hkjbwf {
  padding: 0px !important;
}

.css-1lo6yt6 {
  max-height: fit-content !important;
  overflow: visible;
}

.css-1qzx1tr {
  overflow: hidden;
  -webkit-transition: max-height 0.25s ease-in-out;
  transition: max-height 0.25s ease-in-out;
  max-height: fit-content !important;
}

/* .TextField {
    width: fit-content;
  } */

.linkclass {
  color: #2965cb;
  text-decoration: none;
}

.linkclass:hover {
  text-decoration: underline;
}

.SectionMessage {
  color: #172b4d;
  padding: 15px 15px 15px 40px;
  position: relative;
  word-wrap: break-word;
  word-break: normal;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px 5px 5px;
}

.FormHeader {
  color: #2965cb;
  padding-left: 25px !important;
  font-size: 26px;
  font-weight: 500;
  align-content: left;
}

.formContainer {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 150px;
  padding-bottom: 30px;
}

.formFields {
  font-size: 16px;
}

.textField {
  max-width: 165px;
  box-sizing: border-box;
}

.loginButton {
  margin-top: 12px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.fieldInput {
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}


.otp-menu-button{
  display: flex;

}
.otp-menu-button> div {
  margin: -11px;
  padding: 14px;
}
