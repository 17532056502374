#contentsection {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  clear: both;
  position: relative;
  background: #fff;
}

section {
  display: block;
}

#contentsection:before {
  content: "";
  clear: both;
  display: table;
}
.page-panel-inner {
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.aui-page-panel-nav {
  background: #ffffff;
  padding: 20px 10px;
  border-right: 1px solid #dfe1e6;
  width: 240px;
  box-sizing: border-box;
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}
.auiPagePanelContent {
  box-sizing: border-box;
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}

.group-aui {
  display: table;
  box-sizing: border-box;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  margin: 10px 0 0;
}

.item-aui {
  box-sizing: border-box;
  display: table-cell;
  margin: 0;
  vertical-align: top;
  border-spacing: 0;
}

.contentSection {
  margin-top: 85px;
  padding: 0;
  box-sizing: border-box;
  clear: both;
  position: relative;
  background: #fff !important;
  clear: both;
  display: table;
}

.ContentPage {
  overflow-x: hidden;
  padding-top: 50px;
}

.pageHeader {
  padding: 20px;
  height: 50px;
}

.pageHeaderName {
  vertical-align: text-top;
  font-size: 26px;
  font-weight: 500;
  padding-left: 15px;
  color: #0052cc;
}

.pagePanel {
  background: #fff;
  border: 1px solid #dfe1e6;
  border-left-width: 0;
  border-right-width: 0;
  box-sizing: border-box;
  clear: both;
  display: block;
  padding: 0;
  position: relative;
  width: 100%;
}

.pageResume {
  vertical-align: text-top;
  font-size: 20px;
  font-weight: 500;
  padding: 5px;
  color: #0052cc;
}

.dlClass {
  margin-top: 5px;
  margin-bottom: 0px !important;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.dtClass {
  width: 40%;
  float: right;
  clear: right;
  text-align: right;
  vertical-align: top;
  color: #6b778c;
  display: table-cell;
  font-weight: normal;
  text-align: right;
  width: 180px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  letter-spacing: 0;
}

.ddClass {
  width: 60%;
  float: right;
  clear: right;
  font-size: 14px;
  font-weight: 400;
  width: 400px;

  line-height: 1.42857143;
  letter-spacing: 0;
  margin-left: 0px !important;
}

.ContainerInfo {
  width: 400px;
  margin-left: 0px !important;
  margin-bottom: 30px;
  padding-left: 0px !important;
}

.pageSectionTitle {
  vertical-align: text-top;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  color: #0052cc;
}

.groupeList {
  list-style-type: disc;
}

.propertieList {
  list-style-type: none;
}

.properties {
  padding-left: 2px;
}

.groupsButton{
  display: flex;
  justify-content: flex-end;
}


.formgroup{
  display: flex;
}

.groups-table{
  width: 100%;
  border: #d5dde5;
  border-style: groove;}

  .modal-group{
    background-color: #0052cc;

  }

  .modal-body{
    padding-left: 57px !important;
    padding-top: 0px !important;
    font-size: 17px !important;
  }

  .group-section{
    border-block: inset;
  }