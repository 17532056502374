.updateIssueModalBody {
  padding-left: 60px !important;
  padding-top: 45px !important;
  font-size: 17px !important;
}

.updateIssueModalHeader {
  color: #172b4d;
  position: fixed;
  z-index: 99;
  background-color: #ffffff;
  border-bottom: 1px solid rgb(204, 204, 204);
  font-weight: 400;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

.updateIssueModalFooter {
  color: #172b4d;
  position: fixed;
  z-index: 99;
  background-color: #ffffff;
  top: 400px;
  width: 760px;
  margin-top: 58px;
  padding: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);
  font-weight: 400;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.updateIssueModalContainer {
  border-radius: 10px;
  border-color: rgb(204, 204, 204);
}

.iGUNw {
  padding-left: 0;
}

.buttonsContainer {
  border-top: 1px solid #dfe1e6;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px !important;
  width: 100%;
}

.cOzZKV {
  height: 600px;
}

.cxjyBr {
  padding: 2px 0px !important;
}

.sc-fjdhpX {
  padding-left: 0px !important;
}
