.navMenuBody {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 40px !important;
}

.PrimaryItem {
  font-weight: 400 !important;
}

.css-1v0n6pv-AtlassianNavigation {
  height: 40px !important;
  border-style: none;
}

.searchInput {
  background-color: blue;
}

.css-18yfcg7 {
  height: 40px !important;
  border-style: none;
}

.dialog{
  width: 18% !important;
  
}


.element-title{
  font-size: small;
  color: #172b4d;
  font-size: 14px;

}

.elements{
  margin-left: 2%;
  margin-bottom: 7%;
  width: 430%;
  padding-right: 7%;
  padding-left: 20%;
  cursor:pointer;
 
}

.elements:hover {
  background-color:#faf8f6;
  text-decoration:none;
  color:#FFFFFF;
}



.list-elements{
  width: 258px;
}

.list-containt{
  width: 20%;
}

.element-key{
  font-size: 12px;
  color: #5e6c84;;
}

.elemenet-summart{
  font-size: small;
  color: #172b4d;
  font-size: 14px;
  margin: 1%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    

}

.dialog{
  width: 20%;
}


.logo{
  padding-right: 2%;
}

.search-title{
  color: #6b778c;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.45454545;
  letter-spacing: 0;
  text-transform: uppercase;
}
.search-title.p{
margin-top: 10%;
}

.styled__Container-sc-1v7azd6-0{
  width: 20% !important;

}
