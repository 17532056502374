.bar {
  width: "240px" !important;
  height: "100vw" !important;
}

.sibe-button {
  font-size: 12px !important;
}

.css-4nh890 {
  font-size: 12px !important;
}

.sidebar {
  padding-top: 20px;
  max-width: 220px;
  margin-right: 0px !important;
}

.css-1508db7-Grid {
  padding-left: 0px !important;
}

.css-1qkyczh-GridColumn {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.MuiListItem-button {
  padding-left: 16px !important;
}

.Element {
  font-size: 0.92vw;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  color: rgb(66, 82, 110);
}

.MuiList-root {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
