.model-body{
    padding: 2%  19%  !important;
}

.model-page{
    padding-bottom:  15px !important;
}

.comment-panel{
    width: 150%;
}