.datedAside{
    border-top: 1px solid #c1c7d0;
    display: block;
    background: #f4f5f7;
    border-right: none;
    text-align: center;
    border-bottom: 1px solid #c1c7d0;
    height: 38px;
}



.collapsed-comments-line{
    display: block;
    padding-top: 12px;
    border-bottom: 1px solid #c1c7d0;
}

.show-more-comments{
    position: relative;
    top: -17px;
    background: #f4f5f7;
    padding: 0 20px;
    color: #172b4d;
}