.tablecontent {
  background-color: white;
  width: auto !important;
  margin-left: 20px;
  height: auto;
  max-height: 250px;
  padding-bottom: 20px;
}

.linkclass {
  color: #2965cb;
  text-decoration: none;
}
