.ihwgfy {
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px 29px;
  max-width: 960px;
  padding: 0px 6px;
  position: relative;
  margin-left: 32px;
}

.css-ncri10-Grid-show {
  display: flex;
  /* display: flex; */
  box-sizing: border-box;
  margin: 0 0 !important;
  position: relative;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: center !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 16px;
  max-width: calc(var(--ds-columns-num) * 80px + 16px);
  -webkit-column-gap: 16px;
  column-gap: 16px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  color: #172b4d;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  letter-spacing: 0;
}

.name {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
  /* overflow-wrap: break-word; */
  color: #6b778c;
  display: inline-block;
  font-weight: normal;
  padding: 2px 5px 2px 0;
  padding-right: 5px;
  text-align: right;
  width: 150px;
}

.value {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 100%;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-top: 2px;
  position: relative;
  vertical-align: top;
  color: black;
}

.info {
  box-sizing: border-box;
  position: relative;
  /* margin: 0px auto; */
  padding-left: 0px;
  padding-right: 47px;
  max-width: 1140px;
  display: flex;
  width: 400px;
  flex-direction: column;
  justify-content: left;
  margin-left: 0 !important;
}

.css-1rcn0u5-Grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  margin: 0 0 !important;
  position: relative;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: calc(var(--ds-columns-num) * 80px + 16px);
  -webkit-column-gap: 16px;
  column-gap: 16px;
}

.dec-show {
  display: flex;
  box-sizing: border-box;
  margin: 0 0 !important;
  position: relative;
  align-items: flex-start;
  padding: 0 16px !important;
  max-width: 100%;
  -webkit-column-gap: 16px;
  grid-column-gap: 16px;
  column-gap: 16px;
  flex-direction: column;
}
.row-ali {
  margin-left: 60px !important;
  width: 75% !important;
}

.attachment-content {
  border: none;
  border-radius: 3px;
  height: 195px;
  margin: 0 15px 15px 0;
  padding: 0px;
}

.attachment-content:after {
  content: " ";
  display: table;
}

.attachment-thumb {
  height: 145px;
}

.css-ofy9t6-TabsWithMode [role="tabpanel"] {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  /* flex-grow: 1; */
  min-height: 0%;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: -ms-flexbox;
  display: flex;
  padding: 0 8px;
  display: flex;
  display: flex;
  flex-wrap: wrap;
}

.css-2s3fzp [role="tabpanel"] {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  /* flex-grow: 1; */
  min-height: 0%;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: -ms-flexbox;
  display: flex;
  padding: 0 8px;
  display: flex;
  display: flex;
  flex-wrap: wrap;
}

.issueHeader {
  background-color: white;
  display: flex;
  /* display: flex; */
  box-sizing: border-box;
  margin: 0 0 !important;
  position: relative;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: center !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 16px;
  max-width: calc(var(--ds-columns-num) * 80px + 16px);
  -webkit-column-gap: 16px;
  column-gap: 16px;
  height: 55px;
}

.issueTabs {
  background-color: white;
}

.issueMenubuttons {
  background-color: white;
  padding-top: 40px;
  padding-left: 20px;
}

.issueAttachActivi {
  background-color: white;
  margin-top: -16px;
}

.bodyIssue {
  padding-top: 76px;
}

.commentpanel {
  display: block !important;
  width: 65vw !important;
}

.tablistHeader {
  width: 65vw;
}

.addcomment {
  width: 50vw;
}

.buttonsave {
  margin-top: 5px;
}

.mention-input {
  width: auto;
  height: 60px;
}

.styltag {
  background-color: rgba(0, 157, 255, 0.3);
  padding: 3px 0;
}

.state-progress{
  margin-top: 5%;
  padding-left: 4%;
}
