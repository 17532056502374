.fileNameTitleCreate {
  padding-left: 10px;
  padding-top: 0px;
  font-size: 14px;
}

.closeButtonTextAreaCreate {
  z-index: 99;
  float: right;
  padding-top: 0px;
  padding-right: 0px;
}

.buttonCloseCreate {
  padding: 0px !important;
}

.buttonCloseCreate:hover {
  background-color: none;
}

.containerFile {
  width: 520px;
}

/* #content[role="progressbar"] {
  background: blue;
} */

.css-14lvi5p-Bar {
  background: rgb(20, 137, 44) !important;
}

.fileNameStyle {
  text-overflow: ellipsis;
  width: 500px;
}
