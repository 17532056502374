.linkclass {
  color: #2965cb;
  text-decoration: none;
}

.resumeContent {
  display: inline-block;
  overflow: hidden;
  text-align: left;
  vertical-align: top;
}
