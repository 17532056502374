.createContent {
  margin: auto;
  margin-top: 80px;
  width: 750px;
  border-radius: 5px;
  box-sizing: border-box;
  padding-top: 5px;
  height: auto;
}

.contentSection {
  background: #fff;
  border: 1px solid #c1c7d0;
  border-radius: 5px;
  margin-top: 0px !important;
}

.createPanel {
  padding-left: 60px;
  height: auto;
  width: 750px;
  font-size: 17px;
}

.headerCreateIssue {
  color: #2965cb;
  font-size: 24px;
  font-weight: 500;
  align-content: left;
}

.linkclass {
  color: #2965cb;
  text-decoration: none;
  font-weight: 400;
}

.linkclass:hover {
  text-decoration: underline;
}

.formFooter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-start;
  align-content: center;
}

.innerContent {
  margin-bottom: 10px !important;
}

.buttonsContainer {
  border-top: 1px solid #dfe1e6;
  margin-top: 30px;
  margin-bottom: -30px !important;
  padding-top: 20px !important;
  width: 100%;
  padding-left: 0px !important;
}

.singleSelect {
  max-width: 250px;
  font-size: 14px;
}

.SelectField {
  max-height: 25px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.innerContentHead {
  padding-left: 28px;
}

.lineField {
  padding-top: 5px;
}

.buttonsContainer {
  font-size: medium;
  margin-top: 0px !important;
}

.error-panel{
  padding-bottom:8%; 
}

.error-panel-array{
  padding-bottom:0%; 
}


.auiButton{
  border-color:  #DFE1E6 !important;
  border-width: 1 !important;
}