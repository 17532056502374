.advancedSearch {
  height: auto;
  width: 800px;
  max-width: 850px;
  margin-left: 15px;
  align-items: center;
}

.iconTextArea {
  position: relative;
  z-index: 98;

  float: left;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
}

.closeButtonTextArea {
  z-index: 98;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
}

.buttonClose {
  padding: 0px !important;
}

.bodyTextArea {
  box-sizing: border-box;
  color: black;
  padding-left: 30px;
  margin-top: 5px;
}

.advancedSearchContainer {
  height: auto;
  padding-bottom: 15px;
}

.searchHeader {
  color: #2965cb;
  font-size: 24px;
  font-weight: 500;
  align-content: "left";
  margin: 0;
}

.rechercheLi {
  margin-left: 30px;
  margin-top: 15px;
}

.borderTextArea {
  border-style: solid;
  border-radius: 5px;
  border-color: #5996ff;
  padding-right: 40px;
}

.datePickerSearch {
  width: 300px;
  color: grey;
  border: 1px solid rgb(204, 204, 204);
}

.responsableSearch {
  width: 280px;
  height: 340px;
  color: grey;
  border: 1px solid rgb(204, 204, 204);
}

.createurSearch {
  width: 280px;
  height: 340px;
  color: grey;
  border: 1px solid rgb(204, 204, 204);
}
