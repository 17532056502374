.linkclass {
  color: #2965cb;
  text-decoration: none;
}

.headerSummary {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.01em;
  color: #244fc9;
}
