.fieldName {
  float: left;
  padding-top: 3px;
  padding-right: 5px;
  color: rgb(128, 128, 128);
  font-size: 14px;
}

.fieldVal {
  float: left;
  padding-top: 5px;
  padding-right: 5px;
  color: rgb(36, 36, 36);
  font-size: 14px;
  display: flex;
}

.infoContainer {
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 800px;
}

.infoTitle {
  margin-left: 30px !important;
}

.infoTitleText {
  font-size: 14px;
}

.tabPanelContainer {
}

.circle {
  background-color: #00ff00;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.circle-red {
  background-color: #ff0000;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.circle-yellow {
  background-color: #fbff00;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.circle-orange {
  background-color: #ff6f00;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.css-1h65th5-ButtonBase:hover{
  background-color: #c1c1c1 !important;
}
