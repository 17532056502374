body {
  background-color: #f4f5f7 !important;
}

.title {
  font-size: 30;
}

.formstyle {
  background-color: #f4f5f7;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
}

.footer-style {
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
}

.footer {
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  width: 50%;
}

.formcontainer {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 1 px solid #c1c7d0;
  border-radius: 5 px;
  display: inline-block;
  margin: 50 px auto 0 o !important;
  padding: 40 px !important;
  text-align: left;
  width: 100% !important;
  max-width: 100% !important;
  padding-left: 122px;
}

.aui-page-panel {
  background: #fff;
  border: 1 px solid #c1c7d0;
  border-radius: 5 px;
  border-left-width: 0;
  border-right-width: 0;
  box-sizing: border-box;
  margin: 50px auto 0;
  padding: 40px;
  height: 50%;
  width: 41%;
  max-width: 41%;
}
.footer {
  clear: both;
  float: left;
  width: 100%;
  display: block;
}

.footer-body {
  color: #7a869a;
  font-size: 12px;
  line-height: 1.66666667;
  margin: 20 px 0;
  padding: 0 10 px 21 px;
  min-height: 44 px;
  text-align: center;
}
.atlassian-footer {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin: 10 px 0 0;
}

.ul {
  display: block;
  font-size: 0;
  list-style: none;
  padding: 0;
}

.li {
  display: inline-block;
  font-size: 12px;
  line-height: 1.66666667;
  padding: 0;
  white-space: nowrap;
}
li::after {
  content: "\B7";
  margin-left: 10 px;
  speak: none;
}

.a {
  color: #5e6c84;
  text-decoration: underline;
}

.spaceFooter {
  margin: 10 px 0 0;
}

#footer-logo {
  background: #f5f5f5;
  position: relative;
  bottom: -21 px;
}

.footer-logo-a {
  background-position-x: center;
  background-position-y: bottom;
  background-size: 127 px 26 px;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: 127 px 26 px;
}

.aui-message-err {
  max-width: fit-content;
  margin: "auto";
  word-wrap: break-word;
  background-color: black !important;
}

.css-1lo6yt6 {
  background-color: #181715;
  color: #2965cb;
  max-height: 55 px;
  overflow: visible;
}
p {
  word-break: normal;
  white-space: pre-wrap;
}

.css-hkjbwf {
  padding: 0px !important;
}

.css-1lo6yt6 {
  max-height: fit-content !important;
  overflow: visible;
}

.css-1qzx1tr {
  overflow: hidden;
  -webkit-transition: max-height 0.25s ease-in-out;
  transition: max-height 0.25s ease-in-out;
  max-height: fit-content !important;
}
.headerbannerLogin .headerBody {
  top: 0px;
}
