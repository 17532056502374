.headerForm {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-block-end: 7px !important;
  display: flex !important;
  flex-flow: row wrap !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important ;
  align-items: flex-end !important;
  padding-block-end: 10px !important;
  padding-block-start: 10px !important;
  justify-content: flex-start !important;
}

.header-panel {
  background-color: #fff;
}

.css-1x681y0-Label {
  font-size: smaller !important;
  font-style: normal !important;
  line-height: 1.1 !important;
  color: #1d1d1e !important;
  font-weight: 600 !important;
  margin-top: 16px !important;
  display: table-row-group !important;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.css-ybd4ww {
  border: none;
  padding: 0px 1px;
  text-align: left;
  border: none;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  text-align: center;
  vertical-align: top;
  color: var(--ds-text-lowEmphasis, var(--local-dynamic-table-text-color));
  width: var(--local-dynamic-table-width);
}

.css-8jk8id-Container {
  margin-bottom: 26px;
  /* margin-right: 15px; */
  padding-left: 32px !important;
  position: relative;
}

.title {
  float: left;
  margin-right: 12px;
  max-width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: small !important ;
  line-height: 2.4375em !important;
  letter-spacing: 0.00938em !important;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, 20px) scale(1);
  -moz-transform: translate(0, 20px) scale(1);
  -ms-transform: translate(0, 20px) scale(1);
  transform: translate(0, 20px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.form {
  margin: 0;
  padding: 0px;
}

.MuiTextField-root > label {
  margin-left: 0% !important;
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  margin-left: 0% !important;
}

.search-form {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

li {
  width: 200px;
  list-style: none;
  align-self: end;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  width: -webkit-fill-available !important;
  margin: 8px !important;
}

.search-label {
  /* float: left; */
  /* margin-left: -145px; */
  padding: 12px 0 0;
  /* position: relative; */
  /* text-align: right; */
  /* width: 130px; */
  /* word-wrap: break-word; */
  /* color: #6b778c; */
  /* font-weight: 500; */
  /* font-size: 14px; */
  margin-left: 0px !important;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  display: flex;
  align-items: center;
}

li::after {
  content: none !important;
  margin-left: 10 px;
}

.searchContent {
  padding-top: 52px;
  padding-left: 50px;
  padding-right: 0px;
  height: 20vw;
}
