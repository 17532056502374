body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

label {
  float: left;
  margin-left: -149px;
  position: relative;
  text-align: right;
  width: 138px;
  word-wrap: break-word;
  color: #6b778c;
  font-weight: 500;
  font-size: 13px;
}
.aui-page-panel-create {
  background: #fff;
  border: 1px solid #dfe1e6;
  border-left-width: 0;
  border-right-width: 0;
  box-sizing: border-box;
  clear: both;
  display: block;
  margin: 20px 0 0;
  padding: 0;
  position: relative;
  width: 50%;
  max-width: 50%;
}
.aui-page-panel-content {
  box-sizing: border-box;
  display: table-cell;
  padding: 0px;
  vertical-align: top;
  width: 78%;
}

.content {
  box-sizing: border-box;
  clear: both;
  position: relative;
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  color: #bdbdbd;
  background-color: #ffffff;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.from-buttons-container {
  box-sizing: border-box;
  clear: both;
  margin: 1px 0 0;
  padding: 4px 0 4px 145px;
  position: relative;
  width: 100%;
}

.aui-button {
  box-sizing: border-box;
  transition: background-color 0.1s ease-out;
  border-radius: 3.01px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-variant: normal;
  font-weight: 400;
  background-image: none;
  background-color: rgba(9, 30, 66, 0.08);
  border: 1px solid transparent;
  color: #344563;
  text-decoration: none;
  display: inline-block;
  height: 2.14285714em;
  line-height: 1.42857143em;
  margin: 0;
  padding: 4px 10px;
  vertical-align: baseline;
  white-space: nowrap;
  margin-right: 3%;
}

.css-64pf72-Field {
  display: flow-root;
  align-content: stretch;
  justify-content: flex-start;
  align-items: center;
}

.long-field {
  max-width: 500px;
}

.tabs-pane {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.css-5a7vsu-container {
  position: relative;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: -webkit-fill-available;
}

.field-group:before,
form.aui .group:after,
form.aui .group:before {
  content: " ";
  display: table;
  padding-top: 2%;
}

.field-group > label,
form.aui:not(.aui-legacy-forms) legend {
  float: left;
  margin-left: -145px;
  padding: 5px 0 0;
  position: relative;
  text-align: right;
  width: 130px;
  word-wrap: break-word;
}

.aui-tabs.horizontal-tabs > .tabs-pane {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

field-group,
form.aui:not(.aui-legacy-forms) .group {
  box-sizing: unset;
  clear: both;
  padding: 4px 0 4px 145px;
  position: relative;
  margin: 1px 0;
  width: 100%;
}
.css-5a7vsu-container {
  pointer-events: all;
  position: relative;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 12px;
}

.css-ncri10-Grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 64px !important;
  max-width: calc(var(--ds-columns-num) * 80px + 16px);
  -webkit-column-gap: 16px;
  column-gap: 16px;
}
.css-1azktg-Grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 16px;
  max-width: 100%;
  -webkit-column-gap: 16px;
  column-gap: -55px;
}

.css-12r78y4 {
  display: flex;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px 73px !important;
  max-width: calc(var(--ds-columns-num) * 80px + 16px);
  column-gap: 16px;
}
