#issue-content {
  background-color: #fff;
  height: 100%;
}

#issue-content .issue-error-content {
  border-top: 0;
}

.issue-body-content {
  padding: 0 20px 20px;
}

#issue-content .issue-body-content .issue-error {
  position: relative;
  text-align: center;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.error-image-canNotBeViewed {
  background-image: url("../../images/error.svg");
}

#issue-content .issue-body-content .issue-error > h1 {
  position: relative;
  text-align: center;
}
