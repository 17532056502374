.css-1bwberi-Label {
  padding-top: 10px;
  font-size: 12px !important;
}

.description {
  margin-top: 7px;
  background-color: #fff !important;
  font-size: 13px;
  color: grey;
}

.description p {
  background-color: #fff !important;
  margin-top: -5px;
  font-size: 13px;
  margin-bottom: 0%;
}

.description body {
  box-sizing: border-box !important;
  overflow: hidden !important;
  position: relative !important;
  height: 18px !important;
  background-color: #fff !important;
}

.checkBox-field{

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40%;
  padding-bottom: -8px;

}

.css-1ss40ai-LabelText {
  text-align: start !important ;
}

.css-1tqulmh-control{
  border-color: #DFE1E6 !important;
}
