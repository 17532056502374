.linkclass {
  color: #2965cb;
  text-decoration: none;
}

.tablecontent {
  background-color: white;
  width: 92.5vw;
  margin-left: 20px;
  margin-bottom: 30px;
  overflow: auto;
  max-height: 270px !important;
}
.dashboard-item-titler {
  margin-top: 0px;
}
