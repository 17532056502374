.addComment {
  color: rgb(0, 0, 0);
  border-radius: 5px;
  border-color: darkgray;
}

/* .TagComment {
  color: blue;
} */

.mentionInput {
  width: auto;
  height: 150px;
  margin-bottom: 10px;
}

.mentionInput__input {
  border-radius: 5px;
  border-color: darkgray;
  border-style: solid;
}

textarea:focus,
input:focus {
  outline: #5a84cc solid 2px;
  border-radius: 5px;
  border-color: #5a84cc;
  border-style: solid;
}

.TagComment {
  background-color: rgb(203, 219, 255);
  padding-right: 5px;
  padding-bottom: 5px;
}
 .CommentSection{
  
 }

 div.DraftEditor-root {
  border: 1px solid #eae5e5;
  height: 200px;
  overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.buttonSave{
  margin-top: 1%;
}