.commentBoxItem {
  border-top-width: 1px;
  width: 63vw;
  height: auto;
  padding-right: 20px;
  border-left-style: solid;
  border-left-color: white;
  border-left-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: rgb(193, 199, 208);
  border-bottom-width: 2px;
}

.commentBoxItem:nth-last-child(2) {
  border-top-width: 1px;
  width: 63vw;
  margin-bottom: 25px;
  border-left-style: solid;
  border-left-color: rgb(53, 114, 176);
  border-left-width: 5px;
  background-color: rgb(244, 245, 247);
}

.commentBoxItem:hover {
  border-top-width: 1px;
  width: 63vw;
  border-left-style: solid;
  border-left-color: rgb(53, 114, 176);
  border-left-width: 5px;
  background-color: rgb(244, 245, 247);
}



.TagComment {
  border-style: none;
}

.contentCommentary * {
  border: 0px !important;
  pointer-events: visible;
}

.CommentAuthor span {
  color: #2965cb !important;
}

/* .TagCommentItem {
  color: #2965cb !important;
} */

.mentions {
  margin: 1em 0;
}

.mentions__mention:hover {
  text-decoration: underline;
  background-color: rgb(146, 179, 255);
  padding-right: 5px;
  padding-bottom: 5px;
}

.mentions__mention {
  background-color: rgb(203, 219, 255);
  padding-right: 5px;
  padding-bottom: 5px;
}


.wmde-markdown {
  -webkit-text-size-adjust: 100%;
  font-family: -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  color: #141b22 !important;
  background-color: #00000000 !important;
}

.wmde-markdown ul li{
  list-style: disc !important;
}

.wmde-markdown ol li{
  list-style: decimal !important;
}